<script lang="ts">
  import { cn } from '@utils/utils';

  import { Badge } from '@components/ui/badge';

  type Badge = {
    label: string;
  };

  type $$Props = {
    class?: string | undefined;
  };

  let className: $$Props['class'] = '';
  export { className as class };
</script>

<Badge
  {...$$restProps}
  class={cn('text-sm md:text-xs leading-normal font-medium px-4 py-1', className)}
>
  <slot />
</Badge>
