<script lang="ts">
  import { GetAppModalStore } from '@components/GetAppModal/get-app-modal.store';
  import { CdnImage, ExtensionType } from '@components/Images/CdnImage';
  import Modal from '@components/Modal/Modal.svelte';
  import Logo from '@components/SVGs/Logo.svelte';
  import { getAppleLink, getGoogleLink } from '@constants/appStoresUrl';

  const appleLink = getAppleLink();
  const googleLink = getGoogleLink();
</script>

<Modal isOpen={$GetAppModalStore.isModalOpen} on:click={GetAppModalStore.closeModal}>
  <div class="text-center py-12">
    <Logo css="w-[160px] duration-500 ease-linear fill-[#0BE094] m-auto" />
    <h3 class="text-xl font-semibold leading-7 text-center mt-6 mb-2">
      This section is only available in the mobile app
    </h3>
    <p class="text-sm leading-tight font-normal text-gray-500 text-center mb-8">
      If you'd like to view the section, please download our app for your platform of choice using
      the buttons below:
    </p>
    <div class="flex justify-center gap-2">
      <a href={appleLink} class="w-[132px]">
        <CdnImage src="app-store" alt="App Store logo" class="w-full" baseExt={ExtensionType.png} />
      </a>
      <a href={googleLink} class="w-[132px]">
        <CdnImage
          src="google-play"
          alt="Google Play logo"
          class="w-full"
          baseExt={ExtensionType.png}
        />
      </a>
    </div>
  </div>
</Modal>
