<script lang="ts">
  import GetAppModal from '@components/GetAppModal/GetAppModal.svelte';
  import { SectionWrapper } from '@components/ui/section-wrapper';
  import { Typography } from '@components/ui/typography';
  import { ApplyNowButton } from '@pages/_components/ApplyNowButton';

  import BentoCard from './BentoCard.svelte';
  import SmallBadge from './SmallBadge.svelte';

  const COLORS = {
    skyBlue: '204 55% 85%',
    peach: '19 100% 89%',
    mint: '120 19% 80%',
    'dark-cream': '48 35% 90%',
    chartreuse: '79 76% 65%'
  };

  type Badge = {
    label: string;
    color: string;
  };

  const BADGES: Badge[] = [
    { label: 'Home', color: COLORS.skyBlue },
    { label: 'Kitchen', color: COLORS.peach },
    { label: 'Beauty', color: COLORS.mint },
    { label: 'Furniture', color: COLORS.chartreuse },
    { label: 'Pets', color: COLORS['dark-cream'] },
    { label: 'Wellness', color: COLORS.skyBlue },
    { label: 'Fashion', color: COLORS.peach },
    { label: 'Fitness', color: COLORS.mint },
    { label: 'Food & Beverage', color: COLORS['dark-cream'] },
    { label: 'Kids & Baby', color: COLORS.skyBlue }
  ];
</script>

<SectionWrapper id="engage" class="flex-col">
  <article class="flex flex-col items-center w-full gap-4 container mb-16 md:max-w-[786px]">
    <Typography variant="h2" size="6xl" class="md:text-center text-balance">
      Engage to convert
    </Typography>
    <Typography variant="p" size="base" class="md:text-center text-pretty mx-auto">
      Engage with your audience at every stage of their journey, from the first discovery to the
      final purchase decision. Join the fastest-growing online community to boost your brand's
      visibility and foster genuine engagement, converting your audience into long-term customers.
    </Typography>
    <ApplyNowButton />
  </article>

  <div
    class="grid max-w-[1200px] grid-cols-2 md:grid-cols-2 lg:grid-cols-6 gap-4 w-full container md:auto-rows-[minmax(150px,_240px)]"
  >
    <BentoCard
      style="--background: 159 91% 46%"
      class="md:col-span-2 h-[200px] md:h-auto"
      title="5M+"
      description="Users and growing <br /> rapidly"
    />

    <BentoCard
      style="--background: 204 59% 89%"
      title="75%"
      class="text-balance h-[200px] md:h-auto"
      description="Millennials & Gen Z users"
    />

    <BentoCard
      style="--background: 1 88% 66%"
      title="50+"
      class="h-[200px] md:h-auto"
      description="Videos are watched per session"
    />

    <BentoCard
      style="--background: 204 59% 89%"
      class="md:col-span-2 text-pretty"
      title="25+"
      description="Minutes <br /> are watched <br /> per session"
    />

    <BentoCard
      style="--background: 173 72% 18%; --foreground: 120 46% 87%"
      class="col-span-2 md:col-span-3 justify-start gap-2 md:h-auto"
      title="Five thousand+"
      description="Brands live and 100s are joining every week across"
    >
      <div class="flex flex-wrap gap-1 mt-6 md:mt-auto">
        {#each BADGES as badge}
          <SmallBadge style="--primary-foreground: 0 0% 13%; --primary: {badge.color}">
            {badge.label}
          </SmallBadge>
        {/each}
      </div>
    </BentoCard>

    <BentoCard
      style="--background: 120 46% 87%"
      title="12x"
      class="h-[200px] md:h-auto"
      description="Higher <br  /> retention"
    />

    <BentoCard
      style="--background: 14 100% 92%"
      class="h-[200px] md:h-auto"
      title="6x"
      description="Higher conversion <br /> rate"
    />

    <BentoCard
      style="--background: 75 78% 66%"
      class="col-span-2 md:col-span-1 h-[200px] md:h-auto"
      title="700%"
      description="Higher user <br class='hidden md:block' /> engagement <br class='hidden md:block' /> rate"
    />
  </div>

  <GetAppModal />
</SectionWrapper>

<style lang="postcss" global>
  :root #engage {
    --primary: 0 0% 13%;
    --primary-foreground: 0 0% 100%;
    --background: 45 38% 92%;
  }

  :root #engage [data-button-root] {
    --primary: 0 0% 13%;
    --primary-foreground: 45 29% 97%;
  }
</style>
