<script lang="ts">
  export let isOpen: boolean;
</script>

{#if isOpen}
  <div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-[1000]">
    <button class="absolute h-full w-full z-[1] opacity-0" on:click />
    <div
      class="relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
               border w-[32.5rem] max-w-full shadow-lg rounded-md bg-white z-[2]"
    >
      <div class="relative">
        <!-- Modal header -->
        <div class="flex items-start justify-between p-4 rounded-t">
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
            on:click
          >
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-6">
          <slot />
        </div>
      </div>
    </div>
  </div>
{/if}
