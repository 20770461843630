<script lang="ts">
  import { cn } from '@utils/utils';

  import { Card } from '@components/ui/bento';
  import { Typography } from '@components/ui/typography';

  import type { BentoCardProps } from './index';

  type $$Props = BentoCardProps;
  export let title: $$Props['title'];
  export let description: $$Props['description'];
  let className: $$Props['className'];
  export { className as class };
</script>

<Card
  rounded="2xl"
  class={cn('gap-0 flex items-start p-6 justify-between bg-background', className)}
  {...$$restProps}
>
  <Typography variant="span" size="5xl" class="text-[40px] leading-tight">{title}</Typography>
  <Typography variant="span" size="sm" class="">{@html description}</Typography>
  <slot />
</Card>
