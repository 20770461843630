import { writable } from 'svelte/store';

type GetAppModalState = {
  isModalOpen: boolean;
};
const INITIAL_STATE: GetAppModalState = {
  isModalOpen: false
};

const _getAppModalStore = writable<GetAppModalState>(INITIAL_STATE);

const _closeModal = () => {
  _getAppModalStore.update((state) => {
    return {
      ...state,
      isModalOpen: false
    };
  });
};

const _openModal = () => {
  _getAppModalStore.update((state) => {
    return {
      ...state,
      isModalOpen: true
    };
  });
};

export const GetAppModalStore = {
  subscribe: _getAppModalStore.subscribe,
  openModal: _openModal,
  closeModal: _closeModal
};
